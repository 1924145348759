
.table-agenda > thead > tr > th{
    border-bottom: 1px solid #dddddd;
    font-weight: bolder !important;
    padding : 10px 10px 10px 0 !important;
}

.table-agenda > tbody > tr > td {
    padding: 20px 50px 20px 0 !important;
    font-weight: 700 !important;   
    font-family: 'Poppins', sans-serif; 
    border-bottom: 1px solid #dddddd;
}
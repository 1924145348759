.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinAround {
  0% {
    transform: rotate(0deg) translateX(190px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(190px) rotate(-360deg);
  }
}

.animate-spin-around {
  animation: spinAround 10s linear infinite;
}

/* hidden notification ckeditor  */
.cke_notifications_area {
	display: none;
  }


/* Classname untuk konten ckeditor */
.content-ckeditor {
  font-family: 'Arial', sans-serif; /* Font dasar untuk konten */
  line-height: 1.6; /* Line-height untuk membuat teks lebih mudah dibaca */
  color: #333; /* Warna teks default */
  margin: 10px 0; /* Margin antara elemen-elemen di dalam konten */
}

/* Style untuk elemen heading */
.content-ckeditor h1, .content-ckeditor h2, .content-ckeditor h3, .content-ckeditor h4, .content-ckeditor h5, .content-ckeditor h6 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.4;
}

/* Styling untuk heading berdasarkan level */
.content-ckeditor h1 {
  font-size: 2em;
}

.content-ckeditor h2 {
  font-size: 1.75em;
}

.content-ckeditor h3 {
  font-size: 1.5em;
}

.content-ckeditor h4 {
  font-size: 1.25em;
}

.content-ckeditor h5, .content-ckeditor h6 {
  font-size: 1em;
}

/* Style untuk paragraf */
.content-ckeditor p {
  margin-bottom: 15px;
}

/* Style untuk ordered list (numbering) */
.content-ckeditor ol {
  padding-left: 40px; /* Padding kiri untuk memberi ruang bagi numbering */
  margin-bottom: 20px;
  list-style-type: decimal; /* Tipe list numbering */
}

/* Style untuk unordered list (bullet points) */
.content-ckeditor ul {
  padding-left: 40px;
  margin-bottom: 20px;
  list-style-type: disc; /* Tipe bullet point */
}

/* Style untuk elemen list item */
.content-ckeditor li {
  margin-bottom: 10px;
}

/* Style untuk gambar */
.content-ckeditor img {
  max-width: 100%; /* Membuat gambar responsif */
  height: auto;
  display: block;
  margin: 10px auto;
}

/* Style untuk link */
.content-ckeditor a {
  color: #1d72b8; /* Warna link */
  text-decoration: underline;
}

.content-ckeditor a:hover {
  color: #0a58ca; /* Warna link ketika di-hover */
}


.numbered-list {
  list-style: none; /* Menghilangkan gaya default */
  counter-reset: list-counter; /* Mengatur ulang counter untuk daftar */
}

.numbered-list > li:before {
  content: counter(list-counter) '.'; /* Menampilkan nomor sebelum setiap item */
  counter-increment: list-counter; /* Meningkatkan counter untuk setiap item */
  margin-right: 5px; /* Menambahkan sedikit jarak antara nomor dan teks */
}

.awlist1 {
  list-style: none;
  counter-reset: awlistcounter11_0
}

.awlist1>li:before {
  content: counter(awlistcounter11_0) '.';
  counter-increment: awlistcounter11_0
}

.awlist2 {
  list-style: none;
  counter-reset: awlistcounter11_1
}

.awlist2>li:before {
  content: counter(awlistcounter11_0) '.'counter(awlistcounter11_1) '.';
  counter-increment: awlistcounter11_1
}
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

.custom-scroll::-webkit-scrollbar {
	@apply w-1.5 h-1.5;
}

.custom-scroll::-webkit-scrollbar-track {
	@apply bg-transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
	@apply bg-neutral-300  rounded-full;
}

.custom-scroll::-webkit-scrollbar-button {
	display: none;
}

.ps__rail-y {
	@apply group-hover/rail:opacity-100 opacity-0 absolute right-0 w-1 hover:w-1.5 transition-[width,opacity] ease-in-out duration-200;
}

.ps__rail-y > .ps__thumb-y {
	@apply absolute bg-neutral-200  rounded-full w-full right-[3px] transition-all;
}

.bordered {
	@apply border border-neutral-300 ;
}

/* hide autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s;
	-webkit-text-fill-color: #6a6f70 !important;
}

#linear-gradient-2 {
	animation: .6s linear infinite rotate;
 }
 @keyframes rotate { from { transform: rotate(0); } to { transform: rotate(360deg); }  }



 #slider {
    /* position: absolute; */
    width: 350px;
    /* height: 298px; */
    /* background: blue; */
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 1s forwards;
    -webkit-animation: slide-in 1s forwards;
}


    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
#slider-right {
    /* position: absolute; */
    width: 350px;
    /* height: 298px; */
    /* background: blue; */
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.slide-in {
    animation: slide-in 1s forwards;
    -webkit-animation: slide-in 1s forwards;
}


@-webkit-keyframes moving-gradient {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@-moz-keyframes moving-gradient {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes moving-gradient {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.progress-bar {
    /* background: repeating-linear-gradient(120deg, red,green,blue, green, red); */
    background: repeating-linear-gradient(120deg,
        #0193CD 30%,
        #66D4E5 80%,
        #0193CD 100%
    );
    background-repeat:repeat-x;
    height: 3px;
    position: absolute;
    background-size: 400% 100%;
    -webkit-animation: moving-gradient  1s linear infinite;
    -moz-animation: moving-gradient  1s linear infinite;
    animation: moving-gradient  1s linear infinite;
}

.react-datepicker-wrapper {
    display: block !important;
  }

tr.row-lock td {
    background-color: #ffffff;
}

.table-registrasi-detail > tbody > tr > td {
    padding-left: 10px;
    font-size: 12px;
}

.table-preview-email-log tr td {
    padding-left : 5px;
    padding-right: 5px;
    font-size: 14px;
}

.table-preview-email-log tr td:first-child {
    padding-left: 0 !important;
    padding-right: 40px;
}

/* flipped login member */

.perspective {
    -webkit-perspective: 1800px;
            perspective: 1800px;
  }
  
  .page {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform:rotateX(0deg);
            transform:rotateX(0deg);
    cursor: pointer;
  }
  
  .page.turn{
    transform-origin: top left;
    
    /* transition: visibility 0s 2s, opacity 2s linear; */
      -webkit-transform:rotateX(180deg);
              transform:rotateX(180deg);
  }
  
  .front, .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .back {
    transform-origin: top left;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    cursor: pointer;
  }

  .page.flip-back {
    transform-origin: top left;        
    -webkit-transform:rotateX(0deg);
    transform:rotateX(0deg);    
  }


.table-peserta tbody tr td, 
.table-peserta thead tr th {
    padding : 15px 20px;
}

.table-peserta thead tr th { 
    color : white;
    font-weight: normal;    
}

.table-peserta thead tr th:first-child{
    border-top-left-radius: 10px;
}
.table-peserta thead tr th:last-child{
    border-top-right-radius: 10px;
}

.table-peserta tbody tr td {
    border: 1px solid #ddd;
}

.numbered-list {
    list-style: none; /* Menghilangkan gaya default */
    counter-reset: list-counter; /* Mengatur ulang counter untuk daftar */
}

.numbered-list > li:before {
    content: counter(list-counter) '.'; /* Menampilkan nomor sebelum setiap item */
    counter-increment: list-counter; /* Meningkatkan counter untuk setiap item */
    margin-right: 5px; /* Menambahkan sedikit jarak antara nomor dan teks */
}

.alpha-list {
    list-style: none; /* Menghilangkan gaya default */
    list-style: lower-alpha; /* Menghilangkan gaya default */
    counter-reset: list-counter; /* Mengatur ulang counter untuk daftar */
}

.alpha-list > li:before {
    content: counter(list-counter) '.'; /* Menampilkan nomor sebelum setiap item */
    counter-increment: list-counter; /* Meningkatkan counter untuk setiap item */
    margin-right: 5px; /* Menambahkan sedikit jarak antara nomor dan teks */
}